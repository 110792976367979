@import '../../../defaults';

.anw-jobs-header {
  background: url('images/Header_mLP_Personal_RZ.png') 50% 50% / cover no-repeat;
  height: 100vw;

  @include media-breakpoint-up(md) {
    background-image: url('images/Header_LP_Personal_RZ.png');
    height: 50vw;
  }

  @include media-breakpoint-up(xl) {
    background-size: contain;
    height: 680px;
  }
}
